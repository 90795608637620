<template>
    <div class="content">
        <div class="list" v-for="(item,index) in list" :key="index">
            <div>
                <p>{{item.title}}</p>
                <p>{{time(item.ctime)}}</p>
            </div>
            <div :style="item.status===0?'color:rgba(255, 53, 0, 1);':''">
                <p>+{{item.awardText}}元</p>
                <p>{{item.status===0?'已到账':'未到账'}}</p>
            </div>
        </div>
        <div class="listimg" v-if="list.length<1">
            <img src="https://ppyos.xijiuyou.com/2401/mj-empty-img.png" alt="">
            <p>还未兑换，快去集牌吧～</p>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";
import brand from "../../api/brand";
export default {
    data(){
        return{
            page:1,
            list:[],
        }
    },
    mounted(){
        const that = this;
        this.myExchangeRecords();
        window.addEventListener("scroll", () => {
            var ScrollTop, ClientHeight, ScrollHeight;
            ScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            ClientHeight = document.documentElement.clientHeight;
            ScrollHeight = document.documentElement.scrollHeight;
            if (ScrollHeight - ScrollTop - ClientHeight == 0 ) {
                that.page++;
                that.myExchangeRecords();
            }
        });
    },
    methods:{
        time(item) {
        return dayjs(item).format("YYYY-MM-DD HH:mm");
        },
        myExchangeRecords(){
            brand.myExchangeRecords(this.page).then(res=>{
                this.list.push(...res.list)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.content{
    width: 100%;
    min-height: 100vh;
    padding: 0 12px;
    background: #ffffff;
    .listimg{
        padding-top: 160px;
        text-align: center;
        img{
            width: 96px;
        }
        p{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 17px;
        }
    }
    .list{
        display: flex;
        justify-content: space-between;
        padding:  14px 0;
        border-bottom: 1px solid #EEEEEE;
        div{
            line-height: 22px;
        }
        div:nth-child(1){
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            p:nth-child(2){
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
            }
        }
        div:nth-child(2){
            text-align: right;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
        }
    }
}
</style>